<template>
  <div class="menu-container">
    <el-menu :default-active="activeIndex" active-text-color="#ffd04b" background-color="#545c64" class="el-menu-demo"
             mode="horizontal"
             text-color="#fff"
             @select="handleSelect">
      <router-link to="/game">
        <el-menu-item index="1" style="margin-left: 2vw">
          <i class="el-icon-food"></i>游戏信息
        </el-menu-item>
      </router-link>
      <router-link to="/reserve">
        <el-menu-item index="2">
          <i class="el-icon-date"></i>座位管理
        </el-menu-item>
      </router-link>
      <router-link to="/timer">
        <el-menu-item index="3">
          <i class="el-icon-chat-round"></i>
        </el-menu-item>
      </router-link>

    </el-menu>
  </div>
</template>

<script>
// import axios from "axios";
import {eventBus} from '../tlias/event-but';

export default {
  data() {
    return {
      activeIndex: '1',
      activeIndex2: '1'
    };
  },
  methods: {
    handleSelect(key) {
      // console.log('bus发送' + key)
      eventBus.$emit('current-activeIndex', key);
    }
  },
  created() {
    eventBus.$on('current-activeIndex', (key) => {
      this.activeIndex = key.toString();
      // console.log('bus接收' + this.activeIndex.toString())
    })
  },
  activated() {
    // 找到所有的el-menu-item元素，并移除el-menu-item-hover样式类
    const menuItems = this.$el.querySelectorAll('.el-menu-item');
    menuItems.forEach((item) => {
      item.classList.remove('el-menu-item-hover');
    });
  }
}
</script>

<style>
.menu-container {
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  height: 7vh;
  position: fixed;
  left: 0;
  right: 0;
  top:0;
}

.el-menu-item{
  height: 6vh;
  line-height: 56px;
  list-style: none;
}
.el-menu-item .i {
  font-size: min(2vw, 2vh);
}


a {
  width: 100%;
  text-decoration: none;
  color: #f0f0f0;
  font-size: min(2vw, 2vh);
}


.el-menu-demo {
  position: fixed; /* 设置为固定定位 */
  border: none;
  width: 100%;
  min-width: 100vw;
  max-width: 100vw;
  transform: translate(0, -0.5vh);
  display: flex;
  justify-content: space-between; /* 平均分布 */
  height: 6vh !important;
}
/* 关键 */
.el-menu-item{
  height: 6vh !important;
}
</style>
