<template>
    <div id="app">
      <keep-alive>
    <router-view :key="$route.fullPath"></router-view>
      </keep-alive>
    </div>
</template>
<script>
import Vue from "vue";
import HeadmenuView from "@/views/element/HeadmenuView.vue";
export const eventBus = new Vue();
// import axios from "axios";
Vue.component('HeadmenuView', HeadmenuView);
const bus = new Vue();
Vue.prototype.$bus = bus;
export default {
    data() {
        return {
            message: "hello vue",

        }
    },

  mounted() {
  },
  methods: {
    ding(msg) {
      this.$notify({
        title: '恭喜',
        message: msg,
        offset: 500,
        type: 'success'
      });
    },
    boom(msg) {
      this.$notify({
        title: '糟糕',
        message: msg,
        offset: 500,
        type: 'error'
      });
    },
    }
}
</script>

<style>
body{
  margin :0;
}

#app {
    min-height: 100%;
    min-width: 100%;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}

</style>
